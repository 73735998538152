// index.js
'use strict';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import { I18nextProvider } from 'react-i18next';
import React from 'react';
import QueryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import { loadableReady } from '@loadable/component';
import { HelmetProvider } from 'react-helmet-async';
import { addBreadcrumb } from '@sentry/browser';

import './resource/polyfill.js';
import env from './resource/env.js';

import './resource/createTrackingEvent.js';
import './resource/createElementWrapper.js';
import './resource/jsBridgeInterface.js';

import ScrollMemory from './container/ScrollMemory.js';
import { saveContext } from './component/HydrationBoundary.jsx';

import i18n from './resource/i18n.js';
import setIntercomData from './action/setIntercomData.js';
import { push as customerServicePush } from './resource/customerService.js';
import ErrorPage from './page/ErrorPage.jsx';
import { init as initDebug } from './resource/debug.js';
import { setTokenRenewer, createTokenRenewer } from './resource/customFetch.js';
import {
  getCurrentUtm,
  setTrackHeader,
  setHeader,
} from './resource/fetchOptionHeader.js';
import {
  setAppBundleIds,
  getIsOnIOS,
  getIsFbInAppBrowser,
  getIsLineInAppBrowser,
  getIsInPwa,
  getIsIosSafari,
  getIsOnAndroidWebview,
  getIsOnIOSWebview,
} from './resource/getUserAgent.js';
import {
  APP_BUNDLE_IDS,
  ANALYTICS_TRACKING_PUBSUB_TOPIC,
  DEFAULT_OG_IMAGE_URL,
  DEFAULT_OG_TITLE,
  DEFAULT_OG_DESCRIPTION,
  MIXPANEL_INIT_EVENTS,
  BRANDING_NAME,
  MIXPANEL_REPLAY_RECORD_TRIGGERS,
  FEATURE_DRM_CAPABILITY_AUTO_CHECK,
} from './RemoteConfigKeys.js';

import getRemoteConfigData from './selector/getRemoteConfigData.js';

import fetchConfigurationsBootstrap from './action/fetchConfigurationsBootstrap.js';
import updateLanguage from './action/updateLanguage.js';
import mergeMeData from './action/mergeMeData.js';
import setABTestConfig from './action/setABTestConfig.js';
import getFpIdOSS from './action/getFpIdOSS.js';
import {
  initMixpanel,
  setRemoteMeta,
  getMixpanelDistinctId,
  processMixpanelApiCall,
} from './resource/mixpanel.js';
import {
  setSessionId,
  initSentry,
  getSentry,
  Severity,
} from './resource/sentry.js';
import setConfigurations from './action/setConfigurations.js';
import resumeUploadJob from './action/resumeUploadJob.js';
import {
  CONFIG_PRIORITY_CONFIGURE,
  CONFIG_PRIORITY_PRESENCE_CLIENT,
  CONFIG_PRIORITY_PRESENCE_USER,
} from './resource/configPriority.js';
import { preventMutation as preventWindowFunctionsMutation } from './resource/windowFunctions.js';
import { initialize as initApm } from './resource/elasticSearchApm.js';
import getCurrentUnixTimestamp from './resource/getCurrentUnixTimestamp.js';
import { getIsChinaPartner } from './resource/partner.js';
import { clearErrorRecovery } from './resource/errorRecovery.js';
import External from './resource/ExternalModule.js';

const isProd = 'production' === env.NODE_ENV;
const isSSR = env.SERVER_SIDE_RENDER;
const tagName = env.TAG_NAME;
const branchName = env.BRANCH_NAME;
const version = tagName || branchName || 'local';
const isChinaPartner = getIsChinaPartner();
const isInWebview = getIsOnAndroidWebview() || getIsOnIOSWebview();

const initDebugLog = initDebug.extend('log:index');
const initError = initDebug.extend('error:index');

const logMap = {
  default: initDebugLog,
  client: initDebugLog.extend('client'),
  initPreloadedState: initDebugLog.extend('initPreloadedState'),
  initStoreState: initDebugLog.extend('initStoreState'),
  initReactGa: initDebugLog.extend('ReactGa'),
  initMinorModules: initDebugLog.extend('initMinorModules'),
  setSentryTag: initDebugLog.extend('setSentryTag'),
  createApp: initDebugLog.extend('createApp'),
  handleConfigureJsonPayload: initDebugLog.extend('handleConfigureJsonPayload'),
};
const initLog = ({ scope, messages, data }) => {
  addBreadcrumb({
    category: 'init',
    level: Severity.Info,
    message: `${scope} ${messages.join(' ')}`,
    data,
  });
  const logger = logMap[scope] || logMap.default;
  if (data) {
    return logger(...messages, data);
  }
  return logger(...messages);
};

initLog({ scope: 'default', messages: ['init'] });

// Preloading promises
const loadMiddlewareChunksPromise = Promise.all([
  import('./partial/redux-first-history/createReduxHistoryContext.js'),
  import('./reducer/reducer.js'),
  import('./middleware/networkingToaster.js'),
  import('./partial/react-dom/unstable_batchedUpdates.js'),
  import('./middleware/dynamicMiddleware.js'),
  import('./middleware/jwtRefresh.js'),
  import('./middleware/log.js'),
  import('./middleware/persist.js'),
]);
const loadProviderChunksPromise = Promise.all([
  import('./partial/react-redux/Provider.js'),
  import('./partial/redux-first-history/Router.js'),
  import('./component/ErrorBoundary.jsx'),
  import('./component/App.jsx'),
  // don't need to pass lang to i18n on the client side
  // let lang detector to decide the lang
  i18n.init(),
]);
const loadRendererChunksPromise = Promise.all([
  isSSR
    ? import('./partial/react-dom/hydrateRoot.js')
    : import('./partial/react-dom/createRoot.js'),
  import('./partial/immutable/fromJS.js'),
]);
const initRequiredModules = Promise.all([
  (async () => {
    const sentryConfig = {
      environment: isProd ? 'production-client' : 'develop-client',
      level: env.SENTRY_LOG_LEVEL,
      sampleRate: +env.SENTRY_SAMPLE_RATE || 0,
      tracesSampleRate: +env.SENTRY_TRACE_SAMPLE_RATE || 0,
      replaysSessionSampleRate: +env.SENTRY_REPLAY_SESSION_SAMPLE_RATE || 0,
      replaysOnErrorSampleRate: +env.SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE || 0,
      primaryDsn: env.SENTRY_PRIMARY_DSN,
      secondaryDsn: env.SENTRY_SECONDARY_DSN,
    };
    initLog({
      scope: 'client',
      messages: ['init sentry start'],
      data: { sentryConfig },
    });
    await initSentry({ sentryConfig });
    initLog({
      scope: 'client',
      messages: ['init sentry finish'],
      data: { sentryConfig },
    });
    // Make sure to call this after Sentry.init(), since Sentry.init polyfills window functions
    preventWindowFunctionsMutation();
  })(),
  (async () => {
    initLog({
      scope: 'client',
      messages: ['init mixpanel start'],
    });
    try {
      await initMixpanel({
        pubSubTopic:
          window.__PRELOADED_STATE__?.remoteConfig?.[
            ANALYTICS_TRACKING_PUBSUB_TOPIC
          ] || '',
        initEvent:
          window.__PRELOADED_STATE__?.remoteConfig?.[MIXPANEL_INIT_EVENTS] ||
          '',
        replayConfig:
          window.__PRELOADED_STATE__?.remoteConfig?.[
            MIXPANEL_REPLAY_RECORD_TRIGGERS
          ] || '',
      });
      initLog({
        scope: 'client',
        messages: ['init mixpanel finish'],
      });
    } catch (e) {
      initLog({
        scope: 'client',
        messages: ['init mixpanel failed'],
      });
    }
  })(),
]);

const createInitialStorePromise = Promise.all([
  (async () => {
    initLog({ scope: 'initPreloadedState', messages: ['init'] });
    const { default: createStore } = await import('./createInitialStore.js');
    initLog({ scope: 'initPreloadedState', messages: ['chunk loaded'] });
    const query = QueryString.parse(window.location.search);
    const { store } = await createStore({ query });
    const preloadedState = store.getState();
    initLog({
      scope: 'initPreloadedState',
      messages: ['finish'],
      data: { preloadedState: preloadedState.toJS() },
    });
    return preloadedState;
  })(),
  (async () => {
    initLog({ scope: 'initStoreState', messages: ['init'] });
    const { loadState } = await import('./resource/persist.js');
    initLog({ scope: 'initStoreState', messages: ['chunk loaded'] });
    const storageState = await loadState();
    initLog({
      scope: 'initStoreState',
      messages: ['finish'],
      data: storageState,
    });

    const shortFirstId = storageState?.shorts?.firstId;
    if (shortFirstId) {
      addBreadcrumb({
        category: 'ShortAd',
        level: Severity.Debug,
        message: 'first id from persisted store',
        data: {
          firstId: shortFirstId,
        },
      });
    }

    return storageState;
  })(),
]);

const setSentryTag = async (key, value) => {
  initLog({ scope: 'setSentryTag', messages: ['init'] });
  const { setTag } = await getSentry();
  initLog({ scope: 'setSentryTag', messages: ['finish'] });
  return setTag(key, value);
};

const initMinorModules = async () => {
  initLog({
    scope: 'initMinorModules',
    messages: ['init'],
  });

  const initServiceWorker = async () => {
    initLog({
      scope: 'initMinorModules',
      messages: ['done import serviceWorker'],
    });

    const { registerServiceWorker } = await import(
      './serviceWorker/helpers.js'
    );
    registerServiceWorker();

    initLog({
      scope: 'initMinorModules',
      messages: ['done serviceWorker'],
    });
  };

  await Promise.all([initServiceWorker()]);
  initLog({
    scope: 'initMinorModules',
    messages: ['finish'],
  });
};

const handleConfigureJsonPayload = async ({ store, configData }) => {
  initLog({
    scope: 'handleConfigureJsonPayload',
    messages: ['start'],
    data: { configData },
  });
  try {
    const mixpanelMeta = configData?.mixpanel;
    if (typeof mixpanelMeta === 'object' && mixpanelMeta != null) {
      setRemoteMeta({ data: mixpanelMeta });
    }
    const userCountry = configData?.metadata?.country;
    if (userCountry) {
      store.dispatch(mergeMeData({ field: 'country', value: userCountry }));
      store.dispatch(
        mergeMeData({
          field: 'defaultCountryCode',
          value: userCountry.toUpperCase(),
        })
      );
    }
    await store.dispatch(
      setABTestConfig({
        abToken: configData?.ab,
        priority: CONFIG_PRIORITY_CONFIGURE,
      })
    );
    await store.dispatch(
      setConfigurations({
        configData,
        priority: CONFIG_PRIORITY_CONFIGURE,
      })
    );

    initLog({
      scope: 'handleConfigureJsonPayload',
      messages: ['finish'],
    });
  } catch (error) {
    initError('handleConfigureJsonPayload', 'error', error);
    getSentry().then(({ withScope, captureMessage }) => {
      withScope(scope => {
        scope.setFingerprint(['config', 'configure.json']);
        scope.setTag('record', 'configure.json');
        scope.setExtras({ configData });
        captureMessage(error);
      });
    });
  }
};

const autoCheckDrmCapability = ({ store }) => {
  // to distinguish real user or SEO crawler.
  const realUserEvents = ['mousemove', 'click', 'scroll'];
  const check = () => {
    realUserEvents.forEach(event => {
      document.removeEventListener(event, check);
    });
    requestIdleCallback(async () => {
      const checkDrmCapability = (
        await import('./action/checkDrmCapability.js')
      ).default;
      return store.dispatch(checkDrmCapability({ isEarlyLeave: true }));
    });
  };

  realUserEvents.forEach(event => {
    document.addEventListener(event, check);
  });
};

/**
 * @param {object} params
 * @param {*} params.preloadedState
 * @param {ReactDOM.Renderer} params.render
 */
const createApp = async ({ preloadedState, createRoot }) => {
  initLog({
    scope: 'createApp',
    messages: ['init'],
    data: { preloadedState: preloadedState.toJS() },
  });

  const composeEnhancer = isProd
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  setAppBundleIds({
    ids: preloadedState.getIn(['remoteConfig', APP_BUNDLE_IDS])?.split(' '),
  });

  const url = new URL(window.location.href);
  const isInPWA = getIsInPwa();
  const isOnIOS = getIsOnIOS();
  const isIosSafari = getIsIosSafari();
  initLog({ scope: 'createApp', messages: ['check user-agent'] });

  let modifiedPreloadState = preloadedState;
  let clientId;
  try {
    clientId = localStorage.getItem('_clientId');
    // eslint-disable-next-line no-empty
  } catch (_) {}
  clientId = clientId || preloadedState.getIn(['me', 'clientId']) || uuidv4();

  modifiedPreloadState = modifiedPreloadState.setIn(
    ['me', 'clientId'],
    clientId
  );

  processMixpanelApiCall({
    apiNames: ['register'],
    args: [{ $device_id: clientId }],
  });

  modifiedPreloadState = modifiedPreloadState.setIn(
    ['operations', 'chat', 'apiCacheTimestamp'],
    getCurrentUnixTimestamp()
  );
  setHeader({ key: 'X-Client-ID', value: clientId });
  setHeader({
    key: 'X-Version',
    value: version.replace(/^v/i, ''),
  });
  initLog({ scope: 'createApp', messages: ['set fetch headers'] });

  setSentryTag('client_id', clientId);
  setRemoteMeta({
    data: {
      'metadata.client.id': clientId,
    },
  });

  const existUtm = preloadedState.getIn(['me', 'utm'])?.toJS();
  const currentUtm = getCurrentUtm({
    searchParams: url.searchParams,
    existUtm,
  });
  setTrackHeader({
    utmObject: currentUtm,
    trackObject: { mixpanel_distinct_id: getMixpanelDistinctId() },
  });
  initLog({ scope: 'createApp', messages: ['process utms'] });

  if (isOnIOS && isInPWA) {
    const expireTimestamp = preloadedState.get('expireTimestamp');
    const wasHidden = expireTimestamp > Date.now();
    if (!wasHidden) {
      // deleteAll is not in this version of immutableJS
      modifiedPreloadState = modifiedPreloadState.delete('operations');
      modifiedPreloadState = modifiedPreloadState.delete('modals');
      modifiedPreloadState = modifiedPreloadState.delete('routeHistory');
      modifiedPreloadState = modifiedPreloadState.delete('orders');
    }
    modifiedPreloadState = modifiedPreloadState.delete('expireTimestamp');
    // iOS PWA need to refetch device size for re-render if compoent has use device height or width
    modifiedPreloadState = modifiedPreloadState.deleteIn([
      'operations',
      'device',
    ]);

    initLog({ scope: 'createApp', messages: ['clean pwa states'] });
  }

  const [
    { createReduxHistoryContext },
    { createRootReducer },
    { default: networkingToaster },
    { unstable_batchedUpdates },
    { dynamicMiddleware },
    { default: jwtRefresh },
    { default: logMiddleware },
    { persistNowMiddleware },
  ] = await loadMiddlewareChunksPromise;
  initLog({ scope: 'createApp', messages: ['load middleware chunks'] });

  const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({
      history: createBrowserHistory(),
      selectRouterState: state => state.get('router'),
      batch: unstable_batchedUpdates,
    });

  const store = createStore(
    createRootReducer(routerReducer),
    modifiedPreloadState,
    composeEnhancer(
      applyMiddleware(
        jwtRefresh,
        routerMiddleware,
        thunk,
        dynamicMiddleware,
        networkingToaster,
        logMiddleware,
        persistNowMiddleware
      )
    )
  );
  initLog({
    scope: 'createApp',
    messages: ['create store'],
    data: { modifiedPreloadState: modifiedPreloadState.toJS() },
  });

  const history = createReduxHistory(store);
  setTokenRenewer({
    tokenRenewer: createTokenRenewer({
      dispatch: store.dispatch,
      getState: store.getState,
    }),
  });

  window.__getStore__ = () => store;

  window.__externals__ = new External({
    dispatch: store.dispatch,
  });

  window.__externals__.subscribe({
    externalEventTarget: window.__ntp__.eventTarget,
    name: 'timestamp',
  });
  const timestamp = await window.__ntp__.asyncGetNtpTimestamp();
  window.__ntp__.emit(timestamp);

  customerServicePush({
    args: [
      'onUnreadCountChange',
      unreadCount => store.dispatch(setIntercomData({ unreadCount })),
    ],
  });
  customerServicePush({
    args: [
      'update',
      {
        client_id: clientId,
        webapp_version: version,
        webapp_is_pwa: isInPWA,
      },
    ],
    shouldPersist: true,
  });

  const hasFlavor = !!currentUtm['_flavor'];
  if (!isInPWA && !isChinaPartner && !hasFlavor) {
    if (isIosSafari) {
      setTimeout(async () => {
        const addToHomeScreenSafari = (
          await import('./resource/addToHomeScreenSafari.js')
        ).default;
        return addToHomeScreenSafari({ store });
      }, 5000); // TODO: remote config
    } else {
      window.addEventListener('beforeinstallprompt', async event => {
        // Prevent Chrome <= 67 from automatically showing the prompt
        event.preventDefault();
        const addToHomeScreenNotSafari = (
          await import('./resource/addToHomeScreenNotSafari.js')
        ).default;
        return addToHomeScreenNotSafari({ store, promptEvent: event });
      });
    }
  }

  if (isOnIOS && isInPWA) {
    const url = new URL(location.href);
    const action = url.searchParams.get('action');
    const isOAuth = /^login-/gi.test(action);
    const lastRoute = modifiedPreloadState.getIn(['routeHistory', 'stack', 0]);
    // don't override for oauth login.
    if (
      lastRoute &&
      !isOAuth &&
      lastRoute.replace(/\?.*$/, '') !== url.pathname
    ) {
      initLog({ scope: 'createApp', messages: ['replace PWA route'] });
      // from replace to push so that when click go back in PWA, we still have the home route
      history.push(lastRoute);
    }
  }
  if ((getIsFbInAppBrowser() && getIsOnIOS()) || getIsLineInAppBrowser()) {
    const addModal = (await import('./action/addModal.js')).default;
    store.dispatch(
      addModal({
        id: 'InAppBrowserAlert',
        isHigherThanAll: true,
        higherThanIds: ['MobileAppDownloader'],
      })
    );
  }

  const [
    { Provider },
    { Router },
    { default: ErrorBoundary },
    { default: App },
    // we also have init i18n here
  ] = await loadProviderChunksPromise;
  initLog({ scope: 'createApp', messages: ['load provider chunks'] });

  if (window.__FETCH_CONFIGURE_JSON_PROMISE__) {
    const configData = await window.__FETCH_CONFIGURE_JSON_PROMISE__;
    await handleConfigureJsonPayload({ store, configData });
  }

  [CONFIG_PRIORITY_PRESENCE_CLIENT, CONFIG_PRIORITY_PRESENCE_USER].forEach(
    priority =>
      store.dispatch(
        fetchConfigurationsBootstrap({
          priority,
        })
      )
  );

  requestIdleCallback(() => {
    store.dispatch(getFpIdOSS());
  });

  store.dispatch(updateLanguage({ language: i18n.language }));
  initLog({ scope: 'createApp', messages: ['updateLanguage'] });

  // Split the importing and rendering here
  await new Promise(resolve => setTimeout(resolve));

  const handleErrorResolved = async () => {
    const logoutButKeepToken = (await import('./action/logoutButKeepToken.js'))
      .default;
    initLog({ scope: 'createApp', messages: ['handleErrorResolved'] });
    store.dispatch(logoutButKeepToken());
  };

  // save context for partial hydration.
  saveContext({ key: 'store', value: store });
  saveContext({ key: 'history', value: history });
  saveContext({ key: 'i18n', value: i18n });
  saveContext({ key: 'handleErrorResolved', value: handleErrorResolved });
  initLog({ scope: 'createApp', messages: ['saveContext'] });

  await loadableReady();
  initLog({ scope: 'createApp', messages: ['loadableReady'] });

  store.dispatch(resumeUploadJob());

  if (
    getRemoteConfigData(store.getState(), FEATURE_DRM_CAPABILITY_AUTO_CHECK) ===
    1
  ) {
    autoCheckDrmCapability({ store });
  }

  initLog({ scope: 'createApp', messages: ['finish'] });
  initLog({ scope: 'default', messages: ['finish'] });

  const renderPromise = (element, container) => {
    const render = (element, container, resolve) => {
      isSSR
        ? createRoot(container, element)
        : createRoot(container).render(element);
      resolve();
    };
    return new Promise(resolve => render(element, container, resolve));
  };

  const defaultOgImageUrl = getRemoteConfigData(
    store.getState(),
    DEFAULT_OG_IMAGE_URL
  );
  const defaultOgTitle = getRemoteConfigData(
    store.getState(),
    DEFAULT_OG_TITLE
  );
  const defaultOgDescription = getRemoteConfigData(
    store.getState(),
    DEFAULT_OG_DESCRIPTION
  );

  const brandingName = getRemoteConfigData(store.getState(), BRANDING_NAME);

  // need to sync component/HydrationBoundary.jsx if change providers.
  return renderPromise(
    <Provider store={store}>
      <Router history={history}>
        <I18nextProvider i18n={i18n}>
          <ErrorBoundary
            onErrorResolved={handleErrorResolved}
            errorElement={<ErrorPage />}
          >
            <ScrollMemory />
            <HelmetProvider>
              <App
                defaultOgImageUrl={defaultOgImageUrl}
                defaultOgTitle={defaultOgTitle}
                defaultOgDescription={defaultOgDescription}
                brandingName={brandingName}
              />
            </HelmetProvider>
          </ErrorBoundary>
        </I18nextProvider>
      </Router>
    </Provider>,
    document.getElementById('app-root')
  );
};

const client = async () => {
  initLog({ scope: 'client', messages: ['init'] });

  // clear the timeout from error recovery
  clearErrorRecovery();

  const sessionId = uuidv4();
  setHeader({ key: 'X-Session-ID', value: sessionId });
  initApm();

  if (window.__FETCH_CONFIGURE_JSON_PROMISE__) {
    const configData = await window.__FETCH_CONFIGURE_JSON_PROMISE__;
    const mixpanelMeta = configData?.mixpanel;
    if (typeof mixpanelMeta === 'object' && mixpanelMeta != null) {
      setRemoteMeta({ data: mixpanelMeta });
    }
  }

  await initRequiredModules;
  setSessionId({ sessionId });

  requestIdleCallback(initMinorModules);

  initLog({ scope: 'client', messages: ['get storageState init'] });
  const serverSideState = window.__PRELOADED_STATE__ || {};
  delete window.__PRELOADED_STATE__;
  const [[preloadedState, storageState], [{ createRoot }, { fromJS }]] =
    await Promise.all([createInitialStorePromise, loadRendererChunksPromise]);
  let webviewState = {};
  if (isInWebview) {
    const accessTokenFromWebview =
      window.WebApp?.accessToken || //android
      window.webkit?.messageHandlers?.WebApp?.accessToken; // iOS
    webviewState = {
      me: {
        token: accessTokenFromWebview,
      },
    };
  }
  initLog({ scope: 'client', messages: ['get storageState, finish'] });

  initLog({
    scope: 'client',
    messages: ['finish'],
    data: {
      serverSideState,
      preloadedState: preloadedState.toJS(),
      storageState,
      webviewState,
    },
  });
  return createApp({
    createRoot,
    preloadedState: fromJS(serverSideState)
      .mergeDeep(preloadedState)
      // to make sure using merged remote config from SSR
      .mergeDeep({ remoteConfig: serverSideState.remoteConfig })
      .mergeDeep(storageState)
      .mergeDeep(webviewState)
      .mergeDeep(
        fromJS({
          me: {
            sessionId,
          },
        })
      ),
  });
};

client();
