// getFpIdOSS.js
'use strict';
import { MERGE_OPERATION_DATA } from '../ActionTypes.js';
import pushToastr from './pushToastr.js';
import { setHeader } from '../resource/fetchOptionHeader.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import { FEATURE_FINGERPRINT_OSS } from '../RemoteConfigKeys.js';

const isProd = process.env.NODE_ENV === 'production';

/**
 * Get fp oss id
 * @kind action
 * @return {Promise} Action promise.
 */
const getFpIdOSS = () => async (dispatch, getState) => {
  const isFpOssEnabled =
    getRemoteConfigData(getState(), FEATURE_FINGERPRINT_OSS) === 1;
  if (!isFpOssEnabled) {
    return dispatch({ type: '' });
  }

  try {
    const { initFpJsOSS, getFpIdOSS: getFpIdOSSFromIndexedDB } = await import(
      '../resource/fpjs.js'
    );

    await initFpJsOSS();

    const fpIdOSS = await getFpIdOSSFromIndexedDB();

    if (fpIdOSS) {
      setHeader({
        key: 'X-Fingerprint-Oss-Id',
        value: fpIdOSS,
      });
    }

    return dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['fingerprint'],
        data: { fpIdOSS: fpIdOSS || '' },
      },
    });
  } catch (error) {
    if (!isProd) {
      return dispatch(
        pushToastr({
          textKey: error.message,
          color: 'error',
        })
      );
    }
  }
};

export default getFpIdOSS;
